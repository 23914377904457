@use "css/IncludeMedia";
@use "css/Colors.scss";

.RequalificationForm {
    background-color: Colors.$backgorund-white;
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
        display: grid;
      
        label {
            width: 90%;
            display: inline;
            height: 2em;
            margin-left: 0.8em;
      
          @include IncludeMedia.media("<=tablet") {
            height: auto;
            margin-top: 1em;
            width: 100%;
          }
        }
        
        input[type=checkbox] { width: auto; vertical-align: middle; transform: scale(1.3); }
    }

    .FormHeadingBlock {
        margin-left: -0.5rem;
        width: 90%;
    }
    
    .FormHeading {
        font-size: 42px;
        font-weight: 400;
        margin-top: 1rem;
        line-height: 112%;
        width: 90%;
    }
    
    .FormSubHeading {
        font-size: 24px;
        font-weight: 400;
        margin-top: 4rem;
        margin-bottom: 1rem;
        color: Colors.$text-gray;
        line-height: 144.5%;
        width: 80%;
        display: flex;
    }

    .CheckAllHeading {
        margin-top: 2.5em;
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 1rem;
        color: Colors.$text-gray;
        line-height: 144.5%;
        display: flex;
        text-transform: uppercase;
        padding-bottom: 9px;
        max-width: 30rem;
        border-bottom: 1px solid Colors.$text-gray;
    }
}


.Button {
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.5em;
    font-family: "F37Bolton";
    text-transform: uppercase;
    color: Colors.$text-black;
  
    &:hover {
      background: linear-gradient(to right, #4D00FF -8.22%,
          #BD30EB 22.34%,
          #FF61BB 51.64%,
          #FF8F65 83.3%,
          #FFC600 111.54%);
      background-size: 200% auto;
      color: #000;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
  
    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
      #BD30EB 22.34%,
      #FF61BB 51.64%,
      #FF8F65 83.3%,
      #FFC600 111.54%) 1;
  }

.ButtonSection{
  text-align: right;
  margin-top: 1rem 0 1em;
  max-width: 30rem;
}

.FormHeadingColorPart {
    color: #4608F5;
  }

.InputGroup {
    display: inline;
    position: relative;
    border-collapse: separate;
    margin-bottom: 0.5em;
}

.CheckboxLabel {
    width: 80%;
}

.rectangleLine {
    margin-bottom: -0.6em;
}

.vectorLine {
    margin-bottom: 1.5em;
}

.LargeImageSection {
  right: 0;
  float: right;
  z-index: 1;

  @include IncludeMedia.media("<=tablet") {
    display: none;
  }
}

.LargeImage {
  max-width: 700px;
  margin-left: 50px;
  top: 126px;
  left: 856px;
  float: right;
    
  @include IncludeMedia.media(">phone", "<=tablet") {
    margin-top: 40px;
  }
}

.GridGroupRequalifyPage {
  max-width: 530px;
  margin-left: 24%;
  width: 129.09px;
  height: 103px;
  top: -3px;
  position: absolute;
    
  @include IncludeMedia.media(">phone", "<=tablet") {
    display: none;
  }
}

.atLeastOneMessage {
    margin-top: 15px;
    color: red;
    font-size: 14px;
}

@media (max-width: 1300px) {
    .FormHeading {
        font-size: 42px;
        font-weight: 400;
        margin-top: 1rem;
        line-height: 112%;
        width: 100%;
    }
    
    .FormSubHeading {
        width: 100%;
    }

    .GridGroupRequalifyPage {
        display: none;
    }

    .LargeImage {
      display: none;
    }
}

@media (min-width: 900px) and (max-width: 1300px) {
    .FormHeadingBlock {
        margin-left: -1.5rem;
        width: 100% !important;
    }
    
    .FormSubHeading {
        width: 100% !important;
    }

    .rectangleLine {
        margin-bottom: -0.6em;
    }
}

@media (max-width: 900px) {
    .rectangleLine {
        margin-bottom: 2em;
        margin-top: 1.5em;
        max-width: 70%;
    }

    .FormHeadingBlock {
        max-width: 85%;
        padding-left: 1em;
    }
}

@media (max-width: 414px) {
  .Page {
    margin: 15px 0px 0px 0px;
    top: 50%;
    width: 75%;
    padding: 1.5em;
    left: 50%;
    position: absolute;
    display: block;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }

  .rectangleLine {
    width: 80% !important;
    margin-top: 1em;
  }

  .FormHeadingBlock {
      margin-left: 1rem !important;
      margin-bottom: 4em;
      width: 60% !important;
  }

  .FormHeading {
    color: Colors.$text-black;
    font-size: 28px !important;
    line-height: 32px;
    font-weight: 800px;
    bottom: 59.71%;
    right: 9.22%;
    width: 50% !important;
  }
  
  .FormSubHeading {
    top: 42.59%;
    font-size: 18px !important;
    font-weight: 400px;
    line-height: 112%;
    margin: -1em 0 0 0 !important;
    color: Colors.$text-default;
    width: 100% !important;
  }

  .InputGroup {
    font-size: 12px;
    width: 110%;
  }

  .ButtonSection{
      margin-bottom: 4rem;
  }

  .CheckAllHeading {
    font-size: 14px !important;
  }
}