@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.list;
    justify-content: center;
    flex-direction: column;
    padding-top: 1em;
    width: 53%;
    @include IncludeMedia.media("<=tablet") {
        width: 92%;
        padding: 1em;
    }
}

.FloatingCols {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 6em;
    @include IncludeMedia.media("<=tablet") {
        gap: 0;
        flex-wrap: wrap;
        padding: 0;
        justify-content: center;
    }
}

.FloatingGrow {
    flex-grow: 1;
    align-self: center;
}
