@use "css/Colors";
@use "css/IncludeMedia";

.SavingsSection {
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    background-color: Colors.$backgorund-white;
    padding: 1em 3em;
    width: 275px;
    margin-top: 153px;
    margin-left: -100px;
    @include IncludeMedia.media("<=tablet") {
        padding: 1em;
        width: auto;
        margin-bottom: 1em;
    }
    .Title {
        color: Colors.$text-default;
        padding: 0.5em 0;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 24px;
    }
    .Metrics {
        font-size: 3em;
        color: Colors.$text-blue;
        font-weight: bold;
        @include IncludeMedia.media("<=tablet") {
            font-size: 3em;
        }
    }
    .MetricsDetails {
        font-size: 12px;
        line-height: 120%;
        color: #767A82;
        margin-top: 0.75em;
        text-transform: uppercase;
        font-weight: 800;
    }
}

.MetricsRectangle {
    margin-top: 0.45em;
    width: 3px;
    height: 16px;
}

@media (max-width: 900px) {
    .SavingsSection {
        margin-top: 20px;
        width: 80%;
        margin-left: 7%;
        padding-left: 2em;
    }

    .MetricsDetails {
        font-size: 10px;
        margin-left: -2.5em;
        margin-top: 0.7em !important;
    }
}

@media (min-width: 768px) and (max-width: 900px) {
    .SavingsSection {
        width: 80%;
    }

    .MetricsDetails {
        font-size: 14px !important;
        margin-left: -2.5em;
        margin-top: 0.5em !important;
    }
}

@media (min-width: 414px) and (max-width: 768px) {
    .SavingsSection {
        width: 77%;
    }

    .MetricsDetails {
        font-size: 12px !important;
        margin-left: -0.5em;
        margin-top: 0.7em !important;
    }
}

@media (max-width: 414px) {
    .SavingsSection {
        margin-top: 50px;
        width: 80%;
        margin-left: 15px;
        margin-bottom: 50px;
        padding-left: 1.5em;
    }

    .MetricsDetails {
        font-size: 10px !important;
        margin-left: -0.5em;
        margin-top: 1em !important;
    }
}

@media (max-width: 280px) {
    .SavingsSection {
        width: 75%;
        height: 100px;
        padding: 1em;
    }
    
    .Title {
        width: 100%;
        font-size: 16px !important;
        margin-top: -0.6em;
    }

    .Metrics {
        font-size: 1.5em !important;
    }

    .MetricsDetails {
        font-size: 10px !important;
        margin-left: -0.4em;
        margin-top: 0.6em !important;
    }
}