@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.list;
    margin-bottom: 30px;
    padding: 0 2em;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 2em;
    grid-gap: 1em;
    @include IncludeMedia.media("<=tablet") {
        padding: 0em;
    }
}

.FloatingCols{
    @include FloatingCell.list;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    padding: 0 2em;
    @include IncludeMedia.media("<=tablet") {
        padding: 0 1em;
        grid-gap: 2em;
    }
}

.DiySection {
    background-color: Colors.$background-light-grey;
    color: Colors.$text-variant-dark-gray;
    padding: 2em;
    flex-grow: 1;
    .Title {
        text-align: left;
        font-size: 2em;
        padding-bottom: 1em;
        text-transform: uppercase;
        text-align: center;
    }
    .SubTitle {
        text-align: left;
        font-size: 1em;
        padding: 0.5em 0;
        text-transform: uppercase;
        font-weight: 600;
    }
    .FloatTitle {
        padding: 0 0 0.5em 0;
        font-size: 2.5em;
        font-weight: bold;
        text-transform: uppercase;
    }
    .FloatSubTitle {
        text-transform: uppercase;
    }
    hr{
        border: none;
        background-color: Colors.$text-dark-gray;
        height: 0.02em;
    }
    ul {
        list-style-type: disc !important;
        line-height: 1.5em;
        @include IncludeMedia.media("<=tablet") {
            width: auto;
        }
    }
    li::marker {
        font-size: 1em;
    }
}
