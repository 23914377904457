@use "css/Colors.scss";
@use "css/IncludeMedia";

.FaqSection {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    background:#000000;
    ;
    .FaqAList {
        list-style: auto;
        padding-left: 24px;
    }
    .FaqAList li::marker {
        font-size: 1.2em;
        line-height: 1.5em;
    }
    .FaqAList ol {
        list-style-type: decimal !important;
    }
    .FaqQTitle {
        color: #FFFFFF;
        display: grid;
        grid-template-columns: auto 1fr;
        margin: 0;
    }
    .FaqQ {
        color: #FFFFFF;
        font-size: 32px;
        padding-right: 0.2em;
        font-weight: bolder;
    }
    .FaqQText {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #FFFFFF;
    }
    .FaqASection {
        display: grid;
        grid-template-columns: auto 1fr;
        line-height: 1.2em;
    }
    .FaqA {
        color: #FFFFFF;
        font-size: 32px;
        padding-right: 0.45em;
        font-weight: bolder;
        padding-top: 0.5em;
    }
    .FaqAText {
        font-weight: 400;
        font-size: 20px;
        line-height: 149.5%;
        color: #D9D9D9;
    }
}

.FaqHeader{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-bottom: 1rem;
}
/*Imgaes are hidden in mobile views*/
.FaqImage{
    flex-grow: 1;
    margin-top: -8rem;
    margin-right: 5rem;
    @include IncludeMedia.media("<=tablet") {
        display: none;
    }
}
.FaqBackground{
    opacity: 0.5;
    margin-left: -5%;
    max-height: 10vh;
    @include IncludeMedia.media("<=tablet") {
        display: none;
    }
}
.FaqTitle {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 2em;
    padding-left: 10%;

    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 48px;
    text-align: left;
    color: #F8F7F7;
    text-transform: uppercase;

    @include IncludeMedia.media("<=tablet") {
        width: 100%;
        padding: 1rem;
        font-size: 30px;
    }
}

:global {
    .faq-row-wrapper {
        padding: 3% 10% 1% 10%;
        background: #000000!important;
        @include IncludeMedia.media("<=tablet") {
            padding: 0em;
        }
    }
    .faq-row-wrapper .faq-body .faq-row {
        padding: 0.75em 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        margin: 2em 0;
        background: #000000;
        border-bottom: none !important;
        @include IncludeMedia.media("<=tablet") {
            padding: 0rem 1rem;
        }
    }

    .faq-row-wrapper .faq-body .faq-row .row-title {
        font-size: larger;
        color: #FFFFFF!important;
        padding-top: 2.5em !important;
        background: url('../../../images/icons/FaqDivider.svg') no-repeat;
        @include IncludeMedia.media("<=tablet") {
            padding-left: 1em!important;
        }
    }
    .faq-row-wrapper .faq-body .faq-row .row-content-text {
        font-weight: 400;
        font-size: 20px;
        line-height: 149.5%;
        color: #D9D9D9;
    }
    .faq-row-wrapper .faq-body .faq-row .row-content-text p{
        vertical-align: middle;
        padding-top: 0.5em;
    }
    .faq-row-wrapper .faq-body .faq-row .row-content-text p a{
        color: #D9D9D9 !important;
        text-decoration:underline !important;
    }
    .faq-row-wrapper .faq-body .faq-row .row-title .icon-wrapper {
        color: #FFC600!important;
        max-width: 30px!important;
        max-height: 30px!important;
        font-size: 40px;
        top: 40px!important;
    }
}
