@use "css/Colors";
@use "css/IncludeMedia";

.ReportBody {
    padding: 0 4em;
    background: Colors.$backgorund-white;
    @include IncludeMedia.media("<=tablet") {
        padding: 0;
    }

    .CTAButton {
        padding: 2em 0em 2em 4em;
        @include IncludeMedia.media("<=tablet") {
            padding: 2em 0;
            text-align: center;
        }
    }
}

.Button {
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.5em;

    text-transform: uppercase;
    color: Colors.$text-black;
    font-family: "F37Bolton";
    &:hover {
        background: linear-gradient(to right, #4D00FF -8.22%,
                #BD30EB 22.34%,
                #FF61BB 51.64%,
                #FF8F65 83.3%,
                #FFC600 111.54%);
        background-size: 200% auto;
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
        #BD30EB 22.34%,
        #FF61BB 51.64%,
        #FF8F65 83.3%,
        #FFC600 111.54%) 1;
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}
