@use "css/Colors.scss";
@use "css/IncludeMedia";

.FullBleedHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;

    .Logo {
      height: 2rem;
      margin-left: 3em;
      margin-top: 1.5em;
      margin-bottom: 1.5em;

      @include IncludeMedia.media("<=tablet") {
        margin-left: 0em;
        padding: 0.5em 2em;
      }
    }
}

.WhiteHeader{
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  margin-top: 23px;
  position: inherit;
  
  .Logo {
    width: 168px;
    height: 2rem;
    margin-left: 3.5em;

    @include IncludeMedia.media("<=tablet") {
      margin-left: 1em;
    }
  }
}