@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.ProcessSection {
    color: Colors.$text-light-on-dark;
    background-color: Colors.$backgorund-white;
    padding: 1em 0;
    .SectionTitle {
        color: Colors.$text-black;
        width: 55%;
        padding: 1em 0;
        font-size: 1.75em;
        @include IncludeMedia.media("<=tablet") {
            padding: 1em;
            text-align: center;
            width: auto;
        }
    }
    .SectionIcon{
        float: left;
        margin: 0em 1em 2em 0;
        @include IncludeMedia.media("<=tablet") {
            display: none;
        }
    }
                
}

.StepsList {
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;
    @include IncludeMedia.media("<=tablet") {
        flex-wrap: wrap;
    }
    .Step {
        display: flex;
        flex-flow: column;
        text-align: left;
        width: 15%;
    }
}

.Step {
    position: relative; 
    .Arrow {
        position: absolute;
        bottom: -38px;
        z-index: 1;
        right: -75px;
        @include IncludeMedia.media("<=tablet") {
            top: 185px;
            left: -83px;
            -webkit-transform: rotate(100deg);
            -moz-transform: rotate(100deg);
            -o-transform: rotate(100deg);
            -ms-transform: rotate(100deg);
            transform: rotate(100deg);
        }
    }
  }

.StepsList {
    padding: 2em;
    justify-content: center;
    @include IncludeMedia.media("<=tablet") {
        margin-left: 3em;
    }
    .Step {
        @include IncludeMedia.media("<=tablet") {
            width: 100%;
            min-height: 10em;
        }
        background-color: Colors.$background-variant-blue;
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
        padding: 1.5em 1em;
        font-size: 1.2em;
        .Title {
            font-weight: 700;
            margin-bottom: 1em;
            text-transform: uppercase;
        }
        .Content {
            line-height: 1.2em;
        }
        .Icon {
            text-align: right;
        }
        .Arrow {
            width: 135px;
        }
    }
}
