$background-grey: #f4f6f8;
$backgorund-white: #ffffff;
$background-light-blue: #ecf8f8;
$background-dark-blue: #0E2E41;
$border-light: #c9e4e3;
$background-light-blue-v2:#D2EEED;
$background-light-grey:#F2F4F5;
$background-black: #000000;
$background-variant-blue: #4608F5;

$text-default: #0E2E41;
$text-white: #fff;
$text-dark-blue: #002F43;
$text-black: #000000;
$text-light-blue: #566E7B;
$text-gray: #585858;
$text-variant-dark-gray: #505050;
$text-variant-cream: #F8F7F7;
$text-variant-gray: #757575;
$text-dark-gray: #767A82;
$text-primary-orange: #e68b63;
$text-yellow: #FFC600;
$text-blue: #4608F5;
$text-rotate: #4608F5;
$text-error: #ff0000;
$text-light-on-dark: #ffffff; // TODO define text-default-on-dark
$text-light-on-light: lighten(desaturate($text-default, 80%), 20%);

$button-background-idle: #e68b63;
$button-background-hover: saturate($button-background-idle, 80%);
