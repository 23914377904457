@use "css/IncludeMedia";

.RequalificationPage {
    position: absolute;
    background: white;
    width: 100%;
    padding: 3em;
    border-radius: 25px;
    left: 3em;
    top: 5em;

    @include IncludeMedia.media("<=tablet") {
        left: 0em;
        top: 4.5em;
        width: auto;
        padding: 1em;
        margin: 2em 1em;
        position: inherit;
    }
}

.App{
    font-family: "F37Bolton";
    font-weight: normal;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overscroll-behavior: none;
    overflow-x: hidden;
  }
