@use "css/Colors.scss";

.AddressCollectionWidget {}

.InputGroup{}

.AddressInput{
  width: 90%;
  border-radius: 25px;
  border: 1px solid #c2c3c5;
  height: 56px;
  margin: 0.75em 0 1.5em 0;
  padding: 0.5em;
  position: absolute;
}

.NextButtonSection{
  text-align: right;
}

.Error{
  color: Colors.$text-default;
  margin: -1.5rem 0 1rem 0;
  font-size: 0.75em;
}

.Button {
  font-family: "F37Bolton";
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  padding: 0.5em;

  text-transform: uppercase;
  color: Colors.$text-black;

  &:hover {
    background: linear-gradient(to right, #4D00FF -8.22%,
        #BD30EB 22.34%,
        #FF61BB 51.64%,
        #FF8F65 83.3%,
        #FFC600 111.54%);
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


  background: none;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 3px;
  border-image: linear-gradient(to right, #4D00FF -8.22%,
    #BD30EB 22.34%,
    #FF61BB 51.64%,
    #FF8F65 83.3%,
    #FFC600 111.54%) 1;
}

@-webkit-keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}