@use "css/Colors.scss";
@use "css/IncludeMedia";

@font-face {
  font-family: "F37Bolton";
  src: url("../../utils/fonts/F37Bolton.otf") format("opentype");
}

.Page {
  position: absolute;
  background: white no-repeat center center fixed;
  max-width: 700px;
  min-width: 420px;
  padding: 30px;
  margin-left: -5px;
  border-radius: 25px;
  left: 3em;
  top: 5em;
  font-family: "F37Bolton";
  margin-top: 40px;

  @include IncludeMedia.media(">phone", "<=tablet") {
    left: 0em;
    width: auto;
    padding: 1em;
    margin: 3em 1em;
  }
}

.BodySection {
  background: white;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 1.46%;
  right: 0;
  bottom: 0;
  position: absolute;
  overscroll-behavior: none;
  overflow-x: hidden;
  // overflow-y: hidden;
}

.AddressWidget{
  margin-top: 40px;
}

.Heading {
  color: Colors.$text-default;
  font-weight: 550;
  font-size: 62px;
  line-height: 64px;
  text-transform: uppercase;;
}

.SubHeading {
  font-size: 42px;
  margin: 0.75rem 0;
  color: #566E7B;
  line-height: 112%;
}

.RotateText {
  margin: 15px 0 25px 0px;
  color: Colors.$text-rotate;
}

.Wrapper {
  width: 100%;
  display: block;
}

.Section1 {
  margin-left: 500px !important;
  width: 500px;
}

.GridGroupAlternateStartPage {
  max-width: 530px;
  margin-left: 21%;
  width: 129.09px;
  height: 103px;
  top: 60px;
  position: absolute;
    
  @include IncludeMedia.media(">phone", "<=tablet") {
    display: none;
  }
}

.LargeImageSection {
  right: 0;
  float: right;
  z-index: 1;
  margin-top: 110px;
}

.LargeImage {
  max-width: 530px;
  margin-left: 50px;
  top: 126px;
  left: 856px;
  float: right;
    
  @include IncludeMedia.media(">phone", "<=tablet") {
    margin-top: 40px;
  }
}

.BottomContainer {
  overflow: visible !important;
  z-index: 1;
  position: relative;
  top: 25px;
  margin-left: 80px;
  width: 90%;
}

.ImageContainer {
  width: 100%;
}

@media (max-width: 1300px) {
  .Page {
    max-width: 550px;
  }

  .Heading {
    font-size: 48px;
  }

  .SubHeading {
    font-size: 36px;
  }

  .LargeImageSection {
    margin-left: 25px;
    margin-top: 30px;
    width: 540px;
  }

  .BottomContainer {
    overflow: visible !important;
    z-index: 1;
    position: relative;
    margin-top: 30px;
  }

  .ImageContainer {
    width: 100%;
  }

  .GridGroupAlternateStartPage {
    display: none;
  }
}

@media (max-width: 1200px) {
  .Page {
    max-width: 500px;
  }

  .LargeImageSection {
    float: left;
    z-index: 1;
    top: -200px;
    // width: 80%;
  }

  .GridGroupAlternateStartPage {
    display: none;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .Heading {
    font-size: 42px;
    margin-top: -50px;
  }

  .SubHeading {
    font-size: 28px;
  }

  .BottomContainer {
    width: 75%;
  }

  .GridGroupAlternateStartPage {
    display: none;
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .BodySection {
    overflow-y: scroll;
  }

  .BottomContainer {
    top: 0px;
  }

  .LargeImage {
    margin-left: -30px;
  }

  .GridGroupAlternateStartPage {
    display: none;
  }
}

@media (max-width: 900px) {
  .BodySection {
    overflow-y: scroll;
  }

  .LargeImageSection {
    display: none;
  }

  .BottomContainer {
    top: 600px !important;
    position: absolute;
    margin-left: 50px;
    padding-bottom: 55px;
  }

  .Page {
    padding-left: 1em;
  }

  .GridGroupAlternateStartPage {
    display: none;
  }
}

@media (max-width: 414px) {
  .react-geocoder-results {
    max-width: 300px !important;
    width: 80% !important;
    z-index: 5 !important;
    position: absolute;
  }

  .BodySection {
    overflow-y: scroll;
  }

  .Page {
    position: absolute;
    background: white;
    max-width: 80%;
    min-width: 25%;
    margin-top: 18px;
  }

  .RotateText {
    font-size: 19px;
  }

  .Section1 {
    margin-top: 0px !important;
    max-height: 300px !important;
  }

  .LargeImageSection {
    display: none;
  }

  .BottomContainer {
    overflow: visible !important;
    z-index: 1;
    top: 560px !important;
    width: 100%;
    padding-bottom: 55px;
    margin-left: 55px;
    z-index: 2;
    left: -25px;
  }

  .SubHeading {
    font-size: 24px;
    line-height: 115%;
    margin-bottom: -15px;
  }

  .Heading {
    font-size: 40px;
    line-height: 115%;
  }

  .ImageContainer {
    width: 100%;
    max-width: 240px;
    z-index: 3;
  }

  .css-mbfti9-MuiGrid-root>.MuiGrid-item {
    margin-left: 200px;
  }

  .heading {
    font-size: 42px;
    font-weight: 700 !important;
    color: #002F43;
    line-height: 48px;
    width: 328px;
    letter-spacing: -1px;
    font-family: "F37Bolton";
  }

  .subHeading {
    font-size: 20px;
    font-weight: 400;
    margin: 0.75rem 0;
    margin-bottom: 0px;
    line-height: 40px;
    color: #566E7B;
    font-family: "F37Bolton";
  }
}
