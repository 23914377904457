@use "css/Colors.scss";
@use "css/IncludeMedia";

.AccuracyStatement {
    margin: 1em 0;
    padding: 1em 2em;
    color: Colors.$text-default;

    @include IncludeMedia.media(">phone", "<=tablet") {
        margin: 1em;
    }

    .SectionTitle {
        color: Colors.$text-default;
        text-align: center;
        padding: 1em 0;
        font-size: 1.5em;

        @include IncludeMedia.media("<=tablet") {
            padding: 1em;
        }
    }

    .SectionSubTitle {
        font-size: 1.2em;
        line-height: 1.5em;
        text-align: center;
    }

    .CtaLink {
        cursor: pointer;
        color: blue;
        text-decoration: underline;
        font-weight: bolder;
        display: inline;
    }
}
