@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.cell;
    text-align: left;
    background: transparent !important;
    gap: 50px;
    margin-bottom: 30px;
    // justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    position: relative;
    left: -4em;
    bottom: -3em;
    height: 40rem;
    @include IncludeMedia.media("<=tablet") {
        width: 80%;
        padding: 1em;
        position: unset;
        margin-bottom: 0em;
        display: inherit;
        height: auto;
        text-align: center;
        margin-left: 1em;
        margin-right: 1em;
    }
}

.Metric {
    margin-bottom: 1em;
    border-radius: 10px;
}

.MetricsDisplay {
    padding-top: 8em;
    .Backdrop {
        width: 25rem;
        height: 40rem;
        top: 362px;
        left: 911px;
        background-image: url('../../../images/report_bg.jpeg');
        background-size: contain;
        margin-left: 15em;
        margin-right: 10rem;
        @include IncludeMedia.media("<=tablet") {
            width: 100%;
            margin: auto;
            height: 80%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .Metric {
        margin-bottom: 1em !important;
        border-radius: 10px;
    }
}

.GridGroupReportMetricsPage {
    max-width: 530px;
    margin-left: 80%;
    width: 129.09px;
    height: 103px;
    top: -100px;
    position: absolute;
      
    @include IncludeMedia.media(">phone", "<=tablet") {
      display: none;
    }
  }

  @media (max-width: 900px) {
      .MetricsDisplay {
        width: 100%;
        padding-top: 0em;
        padding-bottom: -14em !important;
      }
      .Backdrop {
          width: 700px;
      }
  }

  @media (max-width: 414px) {
      .MetricsDisplay {
        padding-top: 0em;
        padding-bottom: -14em !important;
      }
  }