.ReportPage {
    background: white;
}

.App{
    font-family: "F37Bolton";
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    overscroll-behavior: none;
    overflow-x: hidden;
  }
