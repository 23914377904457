@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.list;
    gap: 10px;
    margin-bottom: 30px;
    justify-content: center;
}

.ProjectBenefitsSection {
    background-color: Colors.$backgorund-white;
    padding: 1em 0;
    color: Colors.$text-default;

    .Title {
        @include IncludeMedia.media("<=tablet") {
            text-align: center;
        }
        color: Colors.$text-black;
        padding: 1em 0 0.5em 2em;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.1em;
        text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
    }
    .BenefitBox {
        background-color: Colors.$backgorund-white;
        color: Colors.$text-black;
        padding: 1em;
        flex-basis: 29%;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        grid-gap: 1em;
        gap: 1em;
        font-size: 1.2em;
        line-height: 1.5em;
        @include IncludeMedia.media("<=tablet") {
            flex-basis: auto;
            width: 100%;
        }
    }
    .BenefitBox p {
        margin: 0.5em 0 0.5em 0;
    }
}
