@use "css/IncludeMedia";
@use "css/Colors";
@use "css/Section";

.ProjectRecommendationSection {
    padding: 1em 1em 1em 0;
    width: 40rem;
    @include IncludeMedia.media("<=tablet") {
        width: auto;
    }
    .Heading {
        font-size: 2.25em;
        margin-bottom: 1em;
        font-weight: 700;
        text-transform: uppercase;
    }

    .Title {
        color: Colors.$text-black;
        text-transform: uppercase;
        margin-bottom: 1em;
        font-style: normal;
        font-weight: 700;
        font-size: 1.1em;
        text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
        @include IncludeMedia.media("<=tablet") {
            font-size: 1.25em;
        }
    }

    .Address {
        color: #767A82;
        margin-bottom: 1em;
        font-size: 1.2em !important;
    }
}

.Button {
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.5em;
    font-family: "F37Bolton";
    text-transform: uppercase;
    color: Colors.$text-black;

    &:hover {
        background: linear-gradient(to right, #4D00FF -8.22%,
                #BD30EB 22.34%,
                #FF61BB 51.64%,
                #FF8F65 83.3%,
                #FFC600 111.54%);
        background-size: 200% auto;
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
        #BD30EB 22.34%,
        #FF61BB 51.64%,
        #FF8F65 83.3%,
        #FFC600 111.54%) 1;
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}
