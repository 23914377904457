@use "css/Colors.scss";
@use "css/IncludeMedia";

.Page {
  position: absolute;
  background: white;
  width: 737px;
  padding: 3em;
  left: 25%;
  top: 226px;

  @include IncludeMedia.media(">phone", "<=tablet") {
    left: 0em;
    top: 9em;
    width: auto;
    padding: 3em;
    margin: 0em 1em;
  }
}

.App {
  background: url(../../images/background-desktop.webp), url(../../images/background-reduced.jpg) no-repeat center center fixed;
  font-family: "F37Bolton";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Heading {
  color: Colors.$text-black;
  font-size: 62px;
  line-height: 64px;
  font-weight: 550;
  text-transform: uppercase;
  bottom: 59.71%;
  right: 9.22%;
  width: 80%;
}

.SubHeading {
  top: 42.59%;
  font-size: 42px;
  font-weight: 400px;
  line-height: 112%;
  margin: 2rem 0;
  color: Colors.$text-default;
}

.RotateText {
  font-weight: normal;
  color: Colors.$text-rotate;
}

.TextboxTitle {
  color: gray;
}

.GridGroupHomePage {
  max-width: 530px;
  margin-left: 60px;
  width: 129.09px;
  height: 103px;
  top: 175px;
  left: 1050px;
  position: absolute;
}

@media (max-width: 1300px) {
  .Page {
    padding: 2em;
    width: 450px;
  }

  .Heading {
    color: Colors.$text-black;
    font-size: 32px;
    line-height: 48px;
    font-weight: 800px;
    text-transform: uppercase;
    bottom: 59.71%;
    right: 9.22%;
    width: 80%;
  }
  
  .SubHeading {
    top: 42.59%;
    font-size: 24px;
    font-weight: 400px;
    line-height: 112%;
    margin: 2rem 0;
    color: Colors.$text-default;
  }

  .GridGroupHomePage {
    left: 670px;
    width: 6%;
    height: 6%;
    top: 195px;
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .Page {
    margin: 20px 0px 0px 0px;
    top: 50%;
    width: 80%;
    padding: 2em;
    left: 50%;
    position: absolute;
    display: block;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }

  .GridGroupHomePage {
    display: none;
  }
}

@media (max-width: 900px) {
  .Page {
    margin: 20px 0px 0px 0px;
    top: 50%;
    width: 80%;
    padding: 2em;
    left: 50%;
    position: absolute;
    display: block;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
  
  .Heading {
    width: 100%;
  }

  .SubHeading {
    line-height: 112%;
  }

  .GridGroupHomePage {
    display: none;
  }
}

@media (max-width: 414px) {
  .Page {
    margin: 15px 0px 0px 0px;
    top: 50%;
    width: 75%;
    padding: 1.5em;
    left: 50%;
    position: absolute;
    display: block;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }

  .Heading {
    color: Colors.$text-black;
    font-size: 28px;
    line-height: 32px;
    font-weight: 800px;
    text-transform: uppercase;
    bottom: 59.71%;
    right: 9.22%;
    width: 80%;
  }
  
  .SubHeading {
    top: 42.59%;
    font-size: 18px;
    font-weight: 400px;
    line-height: 112%;
    margin: 2rem 0 0 0;
    color: Colors.$text-default;
  }
}