@use "css/Colors.scss";
@use "css/IncludeMedia";


.Footer {
    font-family: "F37Bolton";
    flex-direction: row;
    width: 100%;
    background: white;
    top: 1115px;
    text-align: center;
    height: 16px;
    line-height: 112%;
    font-weight: 400px;
    padding: 25px 0 25px 0;
    bottom: 0px;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
  }

  .Footer >a {
    color: #505050 !important;
    text-decoration:none;
  }
