@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.cell;
    text-align: left;
    background: transparent !important;
    gap: 50px;
    margin-bottom: 30px;
    justify-content: center;
}

.ScopeOfWorkSection {
    background: Colors.$text-variant-cream;
    padding: 1em 0;
    padding-left: 1em;
    margin-left: -1em;
    margin-right: -35em;
    @include IncludeMedia.media("<=tablet") {
        padding: 1em;
        margin: 0;
    }
    .Title {
        color: Colors.$text-black;
        padding: 1em 0 0.5em 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.1em;
        text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
    }
    .Divider {
        margin-bottom: 1em;
    }
    .WorkBox {
        background-color: Colors.$backgorund-white;
        padding: 1em;
        flex-basis: 29%;
        align-items: center;
        display: flex;
        height: 3rem;
        justify-content: flex-start;
        grid-gap: 1em;
        gap: 1em;
        font-size: 1.2em;
        line-height: 1.5em;
        margin-bottom: 1.2em;
        color: Colors.$text-black;
        max-width: 36rem;
        @include IncludeMedia.media("<=tablet") {
            font-size: 1em;
            max-width: none;
        }
    }
    .WorkBox p {
        margin: 0.5em 0 0.5em 0;
        min-width: 10em;
        font-size: 0.9em;
        color: Colors.$text-default;
    }
    .WorkBoxIcon {
        height: 3rem;
    }
}
