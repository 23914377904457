@use "css/Colors.scss";
@use "css/IncludeMedia";

.ComEdQualificationForm {
    width: 100%;
    color: Colors.$text-default;


    p {
        font-size: 0.5em;
        margin-top: 1em;
        font-weight: 200;
        line-height: 1.5em;
    }

    .IncomeInput {
        font-size: 16px;
        font-weight: 600;
    }

    fieldset {
        border: none;
        margin: 0;
        padding: 0;
        display: grid;
        width: 35rem;

        label {
            width: 40rem;
            height: 2em;
            font-size: 2em !important;
            font-weight: 100;
            display: inline;
            float: none;

            @include IncludeMedia.media("<=tablet") {
                height: auto;
                margin-bottom: 0.5em;
                width: 100%;
                font-size: 0.9em;
            }
        }

        select {
            width: 30rem;
            border: 1px solid #c2c3c5;
            padding: 0.3em;
            display: block;
            margin-top: 0.8em;

            @include IncludeMedia.media("<=tablet") {
                width: 100%;
            }
        }

        input {
            width: auto !important;
            margin-right: 0.5em !important;
            border: 1px solid #c2c3c5;
            padding: 0.3em;
            font-size: 1em;

            @include IncludeMedia.media("<=tablet") {
                width: 97%;
            }
        }

        input[type=checkbox] {
            width: auto;
            vertical-align: middle;
            transform: scale(1.3);
        }
    }

}

.CheckboxLabel {
    padding: 5px;
}

.BoldText {
    font-weight: bold;
    margin-top: 1em;
}

.ItalicText {
    font-size: 0.5em;
    font-style: italic;
}

.ButtonSection {
    text-align: right;
    margin-top: 1rem 0 1em;
    max-width: 30rem;
}

.FormHeading {
    font-size: 2rem;
    font-weight: bolder;
    margin-bottom: 3rem
}

.FormHeadingColorPart {
    color: #4608F5;
}

.FormSubHeading {
    font-size: 0.75rem;
    font-weight: bolder;
    margin-bottom: 1rem;
    color: gray;
}

.isRequired {
    color: Colors.$text-error;
    font-size: 0.6em;
    text-align: left;
    margin-top: -1em;
    margin-bottom: 2.5em;

    @include IncludeMedia.media("<=tablet") {
        margin-top: -1.5em;
    }
}

.SqftInput {
    position: relative;
    z-index: 2;
    line-height: 15px;
    height: 15px;
    font-size: 0.85rem;
    float: left;
    width: 23.7rem;
    margin-bottom: 0;
    display: table-cell;
    padding: 0.44em;
    border: 1px solid #ccc;
    margin: 0;
    border-right: none;

    @include IncludeMedia.media("<=tablet") {
        width: 75%;
    }
}

.Suffix {
    width: 35rem;
    float: left;

    @include IncludeMedia.media("<=tablet") {
        width: 100%;
    }
}

.InputGroupLabel {
    margin-bottom: 0.5em;
}

.InputGroup {
    position: relative;
    border-collapse: separate;
    margin-bottom: 1.5em;
}

.InputGroupAddon {
    padding: 6px 9px;
    font-weight: 400;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    width: 5em;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
    border-left: none;
    line-height: 15px;
    height: 15px;
    font-size: 0.85rem;

    @include IncludeMedia.media("<=tablet") {
        width: 20%;
    }
}

.GridGroupVerifyPage {
    max-width: 530px;
    margin-left: 33%;
    width: 129.09px;
    height: 103px;
    top: -3px;
    position: absolute;

    @include IncludeMedia.media(">phone", "<=tablet") {
        display: none;
    }
}

.LargeImageSection {
    right: 0;
    float: right;
    z-index: 1;

    @include IncludeMedia.media("<=tablet") {
        display: none;
    }
}

.LargeImage {
    max-width: 700px;
    margin-left: 50px;
    top: 126px;
    left: 856px;
    float: right;

    @include IncludeMedia.media(">phone", "<=tablet") {
        margin-top: 40px;
    }
}

.rectangleLine {
    margin-bottom: 8px;
    margin-right: 15px;
}

@media (max-width: 1300px) {
    .LargeImage {
        width: 100%;
        display: none;
    }
}

@media (max-width: 414px) {
    .ButtonSection {
        margin-bottom: 4.5rem;
    }
}

.Button {
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.5em;
    font-family: "F37Bolton";
    text-transform: uppercase;
    color: Colors.$text-black;

    &:hover {
        background: linear-gradient(to right, #4D00FF -8.22%,
                #BD30EB 22.34%,
                #FF61BB 51.64%,
                #FF8F65 83.3%,
                #FFC600 111.54%);
        background-size: 200% auto;
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
        #BD30EB 22.34%,
        #FF61BB 51.64%,
        #FF8F65 83.3%,
        #FFC600 111.54%) 1;
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}