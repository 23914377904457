@use "css/Section";
@use "css/IncludeMedia";
@use "css/Colors";

.ReportHeader {
    display: flex;
    gap: 1em;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: Colors.$backgorund-white;
    padding: 0.5em 6em;

    @include IncludeMedia.media("<=tablet") {
        padding: 1em;
    }

    .BPLogo {
        height: 2rem;
    }

    .Logos {
        display: flex;
        align-items: center;
    }

    .IthacaLogo {
        height: 50px;
        margin-left: 1em;
    }


    .GPLogo {
        height: 35px;
        margin-left: 1em;
    }

    .CTAHidden {
        @include IncludeMedia.media("<=tablet") {
            visibility: hidden;
        }
    }

}

.Button {
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.5em;
    font-family: "F37Bolton";
    text-transform: uppercase;
    color: Colors.$text-black;

    &:hover {
        background: linear-gradient(to right, #4D00FF -8.22%,
                #BD30EB 22.34%,
                #FF61BB 51.64%,
                #FF8F65 83.3%,
                #FFC600 111.54%);
        background-size: 200% auto;
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
        #BD30EB 22.34%,
        #FF61BB 51.64%,
        #FF8F65 83.3%,
        #FFC600 111.54%) 1;
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}
