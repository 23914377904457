@font-face {
  font-family: "F37Bolton";
  src: url("../utils/fonts/F37Bolton.otf") format("opentype");
}

/* Needed to remove 8px default margin */
body {
  margin: 0;
}

.App {
  font-family: "F37Bolton";
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overscroll-behavior: none;
  overflow-x: hidden;
}

@media only screen and (max-device-width: 830px) {
  .App {
    background: url(../images/background-mobile.webp) no-repeat center center cover fixed;
  }
}

.react-mapbox-ac-input {
  margin: 1.5rem 0;
  width: 98% !important;
  height: 2rem;
}

.react-mapbox-ac-menu {
  width: auto !important;
  position: absolute !important;
  z-index: 9999 !important;
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  margin-top: -1.5rem !important;
  border-top: none !important;
}

.form-control .search .react-mapbox-ac-input {
  width: 40.3rem !important;
}
.react-mapbox-ac-suggestion {
  font-size: 18px;
  margin-bottom: .5rem;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
 
.react-mapbox-ac-suggestion:hover {
  background-color: #58a;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  .react-geocoder input {
    font-size: 16px !important;
  }
}

@include "Base";
