@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.list;
    gap: 30px;
    margin-bottom: 30px;
    padding: 0 2em;
    justify-content: center;
    margin-top: 2em;
    @include IncludeMedia.media("<=tablet") {
        margin-top: 2em;
    }
}

.LeaseBenefitsSection {
    background-color: Colors.$text-variant-cream;
    padding: 1em 5em;
    padding-top: 8em;
    padding-bottom: 5em;
    margin-top: -7em;
    margin-left: -5em;
    margin-right: -5em;
    margin-bottom: -7em;
    .Title {
        color: Colors.$text-black;
        width: 55%;
        padding: 1em 0;
        font-size: 1.75em;
    
        @include IncludeMedia.media("<=tablet") {
            padding: 1em;
            text-align: center;
            width: auto;
        }
    }
    .LeaseSection {
        padding: 1em;
    }
    .LeaseTitle {
        color: Colors.$background-variant-blue;
        font-size: 2.5em;
        font-weight: bold;
        text-align: left;
        @include IncludeMedia.media("<=tablet") {
            text-align: center;
        }
    }
    .LeaseSubTitle {
        text-transform: uppercase;
        font-weight: bold;
        @include IncludeMedia.media("<=tablet") {
            text-align: center;
        }
    }
    .BenefitBox {
        background-color: Colors.$backgorund-white;
        text-align: center;
        padding: 1em;
        width: 15%;
        min-width:150px;
        aspect-ratio: 1 / 1;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        @include IncludeMedia.media("<=tablet") {
            width: 100%;
            aspect-ratio: auto;
        }
    }
    .BenefitBox p {
        margin: 0.5em 0 0.5em 0;
    }
    .SectionIcon {
        float: left;
        margin: 0em 1em 2em 0;

        @include IncludeMedia.media("<=tablet") {
            display: none;
        }
    }
}
