@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.list;
    gap: 25%;
    margin-bottom: 30px;
    padding: 0 2em;
    justify-content: center;
    padding-top: 2em;
    color: Colors.$text-white;
    grid-gap: 5em;
    flex-wrap: nowrap;
    @include IncludeMedia.media("<=tablet") {
        grid-gap: 3em;
        flex-wrap: wrap;
    }
}

.LeaseSubTitle {
    padding: 1.5em;
    padding-top: 0;
    margin-top: 1em;
}

.FloatSubTitle {
    text-transform: uppercase;
}

.FloatingCell {
    align-self: center;
}

.HireBlocPower {
    background-color: Colors.$background-black;
    padding: 2em;
    flex-grow: 2;
    text-align: center;
    .Title {
        color: Colors.$text-white;
        text-align: left;
        font-size: 2.625em;
        padding-bottom: 1em;
        font-weight: 800;
        text-transform: uppercase;
        @include IncludeMedia.media("<=tablet") {
            text-align: center;
        }
    }

    .CentereTitle {
         color: Colors.$text-white;
         text-align: center;
         font-size: 2.625em;
         padding-bottom: 1em;
         font-weight: 800;
         text-transform: uppercase;
         @include IncludeMedia.media("<=tablet") {
             text-align: center;
         }
     }

    .FloatTitle {
        color: Colors.$text-yellow;
        padding: 0 0 0.5em 0;
        font-size: 2.5em;
        font-weight: bold;
        text-align: center;
    }
    .FloatTitleSmall {
        color: Colors.$text-yellow;
        padding: 0 0 0.5em 0;
        font-size: 2em;
        font-weight: bold;
        text-align: center;
    }
    .ListItem {
        color: Colors.$text-white;
        text-align: left;
        font-size: 1em;
        line-height: 2em;

        ul {
            list-style: disc;
            padding: 1.5em;
            border-top: 1px solid #CFCFCF;
            border-bottom: 1px solid #CFCFCF;
        }
    }

    .ComedListItem {
        color: Colors.$text-white;
        text-align: left;
        font-size: 1em;
        line-height: 2em;

        ul {
            list-style: disc;
            padding: 1.5em;
            border-top: 1px solid #CFCFCF;
            border-bottom: 1px solid #CFCFCF;
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            @include IncludeMedia.media("<=tablet") {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
        }

    }
}

.Button {
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0.5em;
    font-family: "F37Bolton";
    text-transform: uppercase;
    color: Colors.$text-white;

    &:hover {
        background: linear-gradient(to right, #4D00FF -8.22%,
                #BD30EB 22.34%,
                #FF61BB 51.64%,
                #FF8F65 83.3%,
                #FFC600 111.54%);
        background-size: 200% auto;
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
        #BD30EB 22.34%,
        #FF61BB 51.64%,
        #FF8F65 83.3%,
        #FFC600 111.54%) 1;
}

@-webkit-keyframes shine {
    from {
        -webkit-mask-position: 150%;
    }

    to {
        -webkit-mask-position: -50%;
    }
}
