@use "css/Section";
@use "css/Colors";
@use "css/IncludeMedia";

.TestimonialsSection {
  background-color: Colors.$backgorund-white;
  line-height: 1.5rem;

  .SectionInner {
    margin: auto;
    padding: 2rem 2rem 3rem 8rem;
    max-width: 100%;
    display: flex;
    flex-flow: nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 4%;
    @include IncludeMedia.media("<=tablet") {
      padding: 0;
      width: 85%;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .MobileImageContent{
    display: none;
    @include IncludeMedia.media("<=tablet") {
      display: block;
      margin-top: 3vh;
    }
  }

  .ImageContent {
    flex-grow: 1;
    @include IncludeMedia.media("<=tablet") {
      display: none;
    }
  }

  .Content {
    justify-content: center;
    flex-direction: column;
    padding-top: 1em;
  }

  .ImageFrame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-top: -25%;
    background: #FFFFFF;
    @include IncludeMedia.media("<=tablet") {
      margin-top: 0%;
    }
  }

  .Image {
    width: 32vw;
    flex: none;
    order: 0;
    flex-grow: 0;
    @include IncludeMedia.media("<=tablet") {
      width: 100%;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 2.9vw;
    line-height: 6vh;
    margin-bottom: 7.5%;
    text-transform: uppercase;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    @include IncludeMedia.media("<=tablet") {
      font-size: 30px;
      margin-bottom: 5%;
      line-height: 4.5vh;
    }
  }

  h2>span {
    color: #4608F5;
  }

  .Quote {
    font-style: normal;
    font-weight: 400;
    font-size: 2.08vw;
    line-height: 120%;
    align-items: center;
    letter-spacing: -0.02em;
    margin-left: 0px;
    color: #000000;
    @include IncludeMedia.media("<=tablet") {
      font-size: 25px;
    }
  }

  .Quote>span {
    color: #4608F5;
  }

  .Citation {
    margin: 2rem 0 3rem 0;
    text-transform: uppercase;
    color: #757575;
    font-weight: 600;
    font-size: 1.2vw;
    @include IncludeMedia.media("<=tablet") {
      font-size: 15px;
    }
  }

  .Button {
    cursor: pointer;
    font-size: 1.2vw;
    font-weight: 700;
    padding: 0.5em;

    text-transform: uppercase;
    color: Colors.$text-black;

    &:hover {
      background: linear-gradient(to right, #4D00FF -8.22%,
          #BD30EB 22.34%,
          #FF61BB 51.64%,
          #FF8F65 83.3%,
          #FFC600 111.54%);
      background-size: 200% auto;
      color: #000;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    background: none;
    border-style: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-image: linear-gradient(to right, #4D00FF -8.22%,
      #BD30EB 22.34%,
      #FF61BB 51.64%,
      #FF8F65 83.3%,
      #FFC600 111.54%) 1;

    @include IncludeMedia.media("<=tablet") {
      margin: 2.5rem 0;
      font-size: 0.8em;
      justify-content: center;
    }
  }

  @-webkit-keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }

    to {
      -webkit-mask-position: -50%;
    }
  }
}