@use "css/Colors";
@use "css/FloatingCell";
@use "css/IncludeMedia";

.FloatingCells {
    @include FloatingCell.list;
    flex-wrap: nowrap;
    gap: 30px;
    margin-bottom: 30px;
    justify-content: center;
    padding: 2em;
    @include IncludeMedia.media("<=tablet") {
        padding: 0;
        flex-wrap: wrap;
        gap: 0;
        grid-gap: 0;
        padding: 0;
    }
}

.ImplementationOptions {
    .Title {
        @include IncludeMedia.media("<=tablet") {
            text-align: center;
            padding: 1em 0;
        }
        color: Colors.$text-black;
        padding: 1em 0 0.5em 2em;
        font-size: 1.1em;
        text-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
        text-transform: uppercase;
        font-weight: 600;
    }
}
